












































import FormMixin from '@/mixins/FormMixin'

export default FormMixin.extend({
  computed: {
    edit(): boolean {
      return !!this.form.id
    }
  }
})
