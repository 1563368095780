















import Vue from 'vue'
import IEdition from '../models/IEdition'
import ListingModel from '@/areas/listings/models/ListingModel'

export default Vue.extend({
  props: {
    edition: Object as () => IEdition
  },
  computed: {
    listings(): ListingModel[] {
      return this.edition?.listingEditions?.map((x) => x.listing) ?? []
    }
  }
})
