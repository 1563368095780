












import Vue from 'vue'
import EditionForm from '../components/EditionForm.vue'
import IEdition from '../models/IEdition'

export default Vue.extend({
  components: {
    EditionForm
  },
  props: {
    value: Object as () => IEdition
  },
  data() {
    return {
      form: null
    }
  },
  computed: {
    title(): string {
      if (this.value) {
        const prefix = this.value.id ? 'Edit' : 'Add'
        return `${prefix} Edition`
      }
      return ''
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form || this.value)
    }
  }
})
